const InvitationType = {
  CustomerAdmin: 'customer-admin',
  CustomerPurchaser: 'customer-purchaser',
  VendorAdmin: 'vendor-admin',
  CustomerAuthorizer: 'customer-authorizer',
  CustomerObserver: 'customer-observer',
  CustomerAccountManager: 'customer-account-manager',
  CustomerCatalogAdmin: 'customer-catalog-admin',
  CustomerDigitalArchiveViewer: 'customer-digital-archive-viewer'
};

export default Object.freeze(InvitationType);
